import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/regions`;

export interface City {
    id: string;
    name: string;
    region?: Region;
}

export interface Region {
    id: string;
    name: string;
    cities: City[];
}


export function getRegions(countryCode: string) {
    return axios.default.get<Region[]>(`${url}/${countryCode}`);
}
