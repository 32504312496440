import { CategoryGroup, ServiceType } from '@/serviceClients/categoryWebServices';

export const getCategoryTree = (vue: Vue, categoryGroups: CategoryGroup[]) => {
    return [
        {
            id: "Online",
            label: vue.$t('Online'),
            children: getSubtree(categoryGroups, ServiceType.Online)
        },
        {
            id: "Offline",
            label: vue.$t('Offline'),
            children: getSubtree(categoryGroups, ServiceType.Offline)
        }
    ]
}

function getSubtree(categoryGroups: CategoryGroup[], serviceType: ServiceType) {
    return categoryGroups
    .filter(cg => cg.serviceType === serviceType)
    .map(c => { return {
        label: c.name,
        id: c.id,
        children: c.categories.map(ca => { return {
          id: ca.id,
          label: ca.name
        };})
      };})
}