import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/hobbiesAndInterests`;

export interface HobbyOrInterest {
    name: string;
    id: string;
}

export function fetchHobbies() {
    return axios.default.get<HobbyOrInterest[]>(`${url}/hobbies`);
}


export function fetchInterests() {
    return axios.default.get<HobbyOrInterest[]>(`${url}/interests`);
}